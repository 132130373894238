import { createWebHistory, createRouter } from "vue-router";
import auth from "./../middleware/auth";
import no_auth from "./../middleware/no_auth";
import { checkPermission, checkPermissions } from "@/assets/js/utils";
import psa_auth from "@/middleware/psa_auth";

const routes = [
  {
    path: "/test",
    name: "Test",
    component: () => import("@/views/Test.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Dashboard/Auth/Login.vue"),
    meta: {
      middleware: no_auth,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Auth/Register.vue"),
    meta: {
      middleware: no_auth,
    },
  },
  {
    path: "/",
    name: "Landing",
    component: () => import("@/views/Landing.vue"),
  },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: () => import("@/views/ComingSoon.vue"),
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("@/views/Faq.vue"),
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import("@/views/ContactUs.vue"),
  },
  {
    path: "/second",
    name: "LandingSecond",
    component: () => import("@/views/LandingSecond.vue"),
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/Search.vue"),
  },
  {
    path: "/search/:id",
    name: "SearchDetail",
    component: () => import("@/views/SearchDetail.vue"),
  },
  {
    path: "/mice-indonesia",
    name: "MiceIndonesia",
    component: () => import("@/views/MiceIndonesia.vue"),
  },

  // INVENTORY LIST
  {
    path: "/list-inventory",
    name: "ListInventory",
    component: () => import("@/views/Inventory/List/Index.vue"),
  },
  {
    path: "/list-inventory/:id/detail",
    name: "ListInventoryDetail",
    component: () => import("@/views/Inventory/List/Detail.vue"),
  },
  {
    path: "/list-inventory/compare",
    name: "ListInventoryCompare",
    component: () => import("@/views/Inventory/List/Compare.vue"),
  },

  // INVENTORY
  {
    path: "/inventory",
    name: "Inventory",
    component: () => import("@/views/Inventory/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },
  {
    path: "/inventory/create",
    name: "CreateInventory",
    component: () => import("@/views/Inventory/Create.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },
  {
    path: "/inventory/create/venue/:id",
    name: "CreateVenue",
    component: () => import("@/views/Inventory/Create/Venue.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },
  {
    path: "/inventory/create/hotel/:id",
    name: "CreateHotel",
    component: () => import("@/views/Inventory/Create/Hotel.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },
  {
    path: "/inventory/create/hotel-venue/:id",
    name: "CreateHotelVenue",
    component: () => import("@/views/Inventory/Create/HotelVenue.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },

  // OTHER POST
  {
    path: "/other-post/:id",
    name: "OtherPost",
    component: () => import("@/views/Other.vue"),
  },

  // Find A Venue
  {
    path: "/find-a-venue",
    name: "FindAVenueIndex",
    component: () => import("@/views/FindAVenue/Index.vue"),
  },

  // HOTEL
  {
    path: "/hotel",
    name: "HotelIndex",
    component: () => import("@/views/Hotel/Index.vue"),
  },
  {
    path: "/hotel/:id",
    name: "HotelDetail",
    component: () => import("@/views/Hotel/Detail.vue"),
  },
  {
    path: "/hotel/compare",
    name: "HotelCompare",
    component: () => import("@/views/Hotel/Compare.vue"),
  },

  // SUCCESS STORY
  {
    path: "/success-story",
    name: "SuccessStoryIndex",
    component: () => import("@/views/SuccessStory/Index.vue"),
  },
  {
    path: "/success-story/:id",
    name: "SuccessStoryDetail",
    component: () => import("@/views/SuccessStory/Detail.vue"),
  },

  // Visitor Guide
  {
    path: "/visitor-guide",
    name: "VisitorGuideIndex",
    component: () => import("@/views/VisitorGuide/Index.vue"),
  },

  // Tools & Resources
  {
    path: "/tools-and-resources",
    name: "ToolsAndResourcesIndex",
    component: () => import("@/views/ToolsAndResources/Index.vue"),
  },

  // VENUE
  {
    path: "/venue",
    name: "VenueIndex",
    component: () => import("@/views/Venue/Index.vue"),
  },
  {
    path: "/venue/:id",
    name: "VenueDetail",
    component: () => import("@/views/Venue/Detail.vue"),
  },
  {
    path: "/venue/compare",
    name: "VenueCompare",
    component: () => import("@/views/Venue/Compare.vue"),
  },

  // DESTINATION
  {
    path: "/destination",
    name: "DestinationIndex",
    component: () => import("@/views/Destination/Index.vue"),
  },
  {
    path: "/destination/:id",
    name: "DestinationDetail",
    component: () => import("@/views/Destination/Detail.vue"),
  },

  // WhyIndonesia
  {
    path: "/why-indonesia",
    name: "WhyIndonesiaIndex",
    component: () => import("@/views/WhyIndonesia/Index.vue"),
  },

  // NEWS
  {
    path: "/news",
    name: "NewsIndex",
    component: () => import("@/views/News/Index.vue"),
  },
  {
    path: "/news/:id",
    name: "NewsDetail",
    component: () => import("@/views/News/Detail.vue"),
  },

  // PSA
  {
    path: "/psa/:slug/login",
    name: "PsaAuthLogin",
    component: () => import("@/views/Dashboard/Psa/Auth/Login.vue"),
  },
  {
    path: "/psa/forgot-password",
    name: "PsaAuthForgotPassword",
    component: () => import("@/views/Dashboard/Psa/Auth/ForgotPassword.vue"),
  },
  {
    path: "/psa/reset-password/:token",
    name: "PsaAuthResetPassword",
    component: () => import("@/views/Dashboard/Psa/Auth/ResetPassword.vue"),
  },
  {
    path: "/psa/:slug/dashboard",
    name: "PsaDashboard",
    component: () => import("@/views/Dashboard/Psa/Index.vue"),
    meta: {
      middleware: psa_auth,
    },
  },
  {
    path: "/psa/:slug/dashboard/profile",
    name: "PsaDashboardProfileIndex",
    component: () => import("@/views/Dashboard/Psa/Profile/Index.vue"),
    meta: {
      middleware: psa_auth,
    },
  },
  {
    path: "/psa/:slug/dashboard/profile/edit",
    name: "PsaDashboardProfileEdit",
    component: () => import("@/views/Dashboard/Psa/Profile/Edit.vue"),
    meta: {
      middleware: psa_auth,
    },
  },
  {
    path: "/psa/:slug/dashboard/meeting",
    name: "PsaDashboardMeeting",
    component: () => import("@/views/Dashboard/Psa/Event/Meeting.vue"),
    meta: {
      middleware: psa_auth,
    },
  },
  {
    path: "/psa/:slug/dashboard/admisssion-prospects",
    name: "PsaDashboardAdmissionProspects",
    component: () => import("@/views/Dashboard/Psa/Event/AdmissionProspects.vue"),
    meta: {
      middleware: psa_auth,
    },
  },
  {
    path: "/psa/:slug/dashboard/waiting",
    name: "PsaDashboardWaiting",
    component: () => import("@/views/Dashboard/Psa/Event/Waiting.vue"),
    meta: {
      middleware: psa_auth,
    },
  },
  {
    path: "/psa/:slug/dashboard/psa/seller/:type?",
    name: "PsaDashboardSeller",
    component: () => import("@/views/Dashboard/Psa/Event/Seller.vue"),
    meta: {
      middleware: psa_auth,
    },
  },
  {
    path: "/psa/:slug/dashboard/buyer/:type?",
    name: "PsaDashboardBuyer",
    component: () => import("@/views/Dashboard/Psa/Event/Buyer.vue"),
    meta: {
      middleware: psa_auth,
    },
  },
  {
    path: "/psa/:slug/dashboard/skip",
    name: "PsaDashboardSkip",
    component: () => import("@/views/Dashboard/Psa/Event/Skip.vue"),
    meta: {
      middleware: psa_auth,
    },
  },
  {
    path: "/psa/:slug/dashboard/notification",
    name: "PsaDashboardNotification",
    component: () => import("@/views/Dashboard/Psa/Event/Notification.vue"),
    meta: {
      middleware: psa_auth,
    },
  },
  {
    path: "/admin/dashboard/psa/meeting",
    name: "DashboardPsaMeeting",
    component: () => import("@/views/Dashboard/Psa/Meeting.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
      roles: ["VISITOR"],
    },
  },
  {
    path: "/admin/dashboard/psa/pavilions",
    name: "DashboardPsaPavilions",
    component: () => import("@/views/Dashboard/Psa/Pavilions.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
      roles: ["VISITOR"],
    },
  },
  {
    path: "/admin/dashboard/psa/admisssion-prospects",
    name: "DashboardPsaAdmissionProspects",
    component: () => import("@/views/Dashboard/Psa/AdmissionProspects.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
      roles: ["VISITOR"],
    },
  },
  {
    path: "/admin/dashboard/psa/waiting",
    name: "DashboardPsaWaiting",
    component: () => import("@/views/Dashboard/Psa/Waiting.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
      roles: ["VISITOR"],
    },
  },
  {
    path: "/admin/dashboard/psa/skip",
    name: "DashboardPsaSkip",
    component: () => import("@/views/Dashboard/Psa/Skip.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
      roles: ["VISITOR"],
    },
  },
  {
    path: "/admin/dashboard/psa/agenda",
    name: "DashboardPsaAgenda",
    component: () => import("@/views/Dashboard/Psa/Agenda.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
      roles: ["VISITOR"],
    },
  },
  {
    path: "/admin/dashboard/psa/buyer/:type?",
    name: "DashboardPsaBuyer",
    component: () => import("@/views/Dashboard/Psa/Buyer.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
      roles: ["BISNIS"],
    },
  },
  {
    path: "/admin/dashboard/psa/seller/:type?",
    name: "DashboardPsaSeller",
    component: () => import("@/views/Dashboard/Psa/Seller.vue"),
    meta: {
      middleware: auth,
      permissions: ["pco"],
      roles: ["VISITOR"],
    },
  },

  // EVENT
  {
    path: "/event",
    name: "EventIndex",
    component: () => import("@/views/Event/Index.vue"),
  },
  {
    path: "/event/:id",
    name: "EventDetail",
    component: () => import("@/views/Event/Detail.vue"),
  },
  {
    path: "/event/:id/mice",
    name: "EventDetailMice",
    component: () => import("@/views/Event/DetailMice.vue"),
  },
  {
    path: "/event/:id/registration/:registrationFormId",
    name: "EventRegistration",
    component: () => import("@/views/Event/Registration.vue"),
  },
  // Request For Support
  {
    path: "/request-for-support",
    name: "RequestForSupport",
    component: () => import("@/views/Rfs/Index.vue"),
  },

  // DASHBOARD
  {
    path: "/admin",
    name: "DashboardAuthLogin",
    component: () => import("@/views/Dashboard/Auth/Login.vue"),
    meta: {
      middleware: no_auth,
    },
  },
  {
    path: "/admin/forgot-password",
    name: "DashboardAuthForgotPassword",
    component: () => import("@/views/Dashboard/Auth/ForgotPassword.vue"),
    meta: {
      middleware: no_auth,
    },
  },
  {
    path: "/admin/reset-password/:token",
    name: "DashboardAuthResetPassword",
    component: () => import("@/views/Dashboard/Auth/ResetPassword.vue"),
    meta: {
      middleware: no_auth,
    },
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard/Index.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/admin/dashboard/profile",
    name: "DashboardProfileIndex",
    component: () => import("@/views/Dashboard/Profile/Index.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/admin/dashboard/profile/edit",
    name: "DashboardProfileEdit",
    component: () => import("@/views/Dashboard/Profile/Edit.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/admin/dashboard/business-profile",
    name: "DashboardBusinessProfileIndex",
    component: () => import("@/views/Dashboard/BusinessProfile/Index.vue"),
    meta: {
      middleware: auth,
      permission: "business",
    },
  },
  {
    path: "/admin/dashboard/business-profile/edit",
    name: "DashboardBusinessProfileEdit",
    component: () => import("@/views/Dashboard/BusinessProfile/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "business",
    },
  },
  {
    path: "/admin/dashboard/roles",
    name: "DashboardRoleIndex",
    component: () => import("@/views/Dashboard/Role/Index.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/company-type",
    name: "DashboardCompanyTypeIndex",
    component: () => import("@/views/Dashboard/CompanyType/Index.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/company-type/create",
    name: "DashboardCompanyTypeCreate",
    component: () => import("@/views/Dashboard/CompanyType/Create.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/company-type/:id/edit",
    name: "DashboardCompanyTypeEdit",
    component: () => import("@/views/Dashboard/CompanyType/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/legality-type",
    name: "DashboardLegalityTypeIndex",
    component: () => import("@/views/Dashboard/LegalityType/Index.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/legality-type/create",
    name: "DashboardLegalityTypeCreate",
    component: () => import("@/views/Dashboard/LegalityType/Create.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/legality-type/:id/edit",
    name: "DashboardLegalityTypeEdit",
    component: () => import("@/views/Dashboard/LegalityType/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/professional-type",
    name: "DashboardProfessionalTypeIndex",
    component: () => import("@/views/Dashboard/ProfessionalType/Index.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/professional-type/create",
    name: "DashboardProfessionalTypeCreate",
    component: () => import("@/views/Dashboard/ProfessionalType/Create.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/professional-type/:id/edit",
    name: "DashboardProfessionalTypeEdit",
    component: () => import("@/views/Dashboard/ProfessionalType/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/accommodation-type",
    name: "DashboardAccommodationTypeIndex",
    component: () => import("@/views/Dashboard/AccommodationType/Index.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/accommodation-type/create",
    name: "DashboardAccommodationTypeCreate",
    component: () => import("@/views/Dashboard/AccommodationType/Create.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/accommodation-type/:id/edit",
    name: "DashboardAccommodationTypeEdit",
    component: () => import("@/views/Dashboard/AccommodationType/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/scope-activity",
    name: "DashboardScopeActivityIndex",
    component: () => import("@/views/Dashboard/ScopeActivity/Index.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/scope-activity/create",
    name: "DashboardScopeActivityCreate",
    component: () => import("@/views/Dashboard/ScopeActivity/Create.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/scope-activity/:id/edit",
    name: "DashboardScopeActivityEdit",
    component: () => import("@/views/Dashboard/ScopeActivity/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/room-type",
    name: "DashboardRoomTypeIndex",
    component: () => import("@/views/Dashboard/RoomType/Index.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/room-type/create",
    name: "DashboardRoomTypeCreate",
    component: () => import("@/views/Dashboard/RoomType/Create.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/room-type/:id/edit",
    name: "DashboardRoomTypeEdit",
    component: () => import("@/views/Dashboard/RoomType/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/activity-type",
    name: "DashboardActivityTypeIndex",
    component: () => import("@/views/Dashboard/ActivityType/Index.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/activity-type/create",
    name: "DashboardActivityTypeCreate",
    component: () => import("@/views/Dashboard/ActivityType/Create.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/activity-type/:id/edit",
    name: "DashboardActivityTypeEdit",
    component: () => import("@/views/Dashboard/ActivityType/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/tool",
    name: "DashboardToolIndex",
    component: () => import("@/views/Dashboard/Tool/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/tool/create",
    name: "DashboardToolCreate",
    component: () => import("@/views/Dashboard/Tool/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/tool/:id/edit",
    name: "DashboardToolEdit",
    component: () => import("@/views/Dashboard/Tool/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/business-unit-list",
    name: "BusinessUnitList",
    component: () => import("@/views/Dashboard/BusinessUnitList/Index.vue"),
    meta: {
      middleware: auth,
      permission: "business_unit_list",
    },
  },
  {
    path: "/admin/dashboard/business-unit-list/:id/claim-accommodation",
    name: "BusinessUnitListClaimAccommodation",
    component: () =>
      import("@/views/Dashboard/BusinessUnitList/ClaimAccommodation.vue"),
    meta: {
      middleware: auth,
      permission: "business_unit_list",
    },
  },
  {
    path: "/admin/dashboard/business-unit-list/:id/claim-venue",
    name: "BusinessUnitListClaimVenue",
    component: () =>
      import("@/views/Dashboard/BusinessUnitList/ClaimVenue.vue"),
    meta: {
      middleware: auth,
      permission: "business_unit_list",
    },
  },
  {
    path: "/admin/dashboard/business-unit-list/approval",
    name: "BusinessUnitListApproval",
    component: () => import("@/views/Dashboard/BusinessUnitList/Approval.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/event-category",
    name: "DashboardEventCategoryIndex",
    component: () => import("@/views/Dashboard/EventCategory/Index.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/event-category/create",
    name: "DashboardEventCategoryCreate",
    component: () => import("@/views/Dashboard/EventCategory/Create.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/event-category/:id/edit",
    name: "DashboardEventCategoryEdit",
    component: () => import("@/views/Dashboard/EventCategory/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "master",
    },
  },
  {
    path: "/admin/dashboard/users",
    name: "DashboardUserIndex",
    component: () => import("@/views/Dashboard/User/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/users/create",
    name: "DashboardUserCreate",
    component: () => import("@/views/Dashboard/User/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/users/:id/edit",
    name: "DashboardUserEdit",
    component: () => import("@/views/Dashboard/User/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/users/:id",
    name: "DashboardUserShow",
    component: () => import("@/views/Dashboard/User/Show.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/banners",
    name: "DashboardBannerIndex",
    component: () => import("@/views/Dashboard/Banner/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/banners/create",
    name: "DashboardBannerCreate",
    component: () => import("@/views/Dashboard/Banner/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/banners/:id/edit",
    name: "DashboardBannerEdit",
    component: () => import("@/views/Dashboard/Banner/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/why-indonesia",
    name: "DashboardWhyIndonesiaIndex",
    component: () => import("@/views/Dashboard/WhyIndonesia/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/why-indonesia/create",
    name: "DashboardWhyIndonesiaCreate",
    component: () => import("@/views/Dashboard/WhyIndonesia/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/why-indonesia/:id/edit",
    name: "DashboardWhyIndonesiaEdit",
    component: () => import("@/views/Dashboard/WhyIndonesia/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/tools-and-resources",
    name: "DashboardToolsAndResourcesIndex",
    component: () => import("@/views/Dashboard/ToolsAndResources/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/tools-and-resources/create",
    name: "DashboardToolsAndResourcesCreate",
    component: () => import("@/views/Dashboard/ToolsAndResources/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/tools-and-resources/:id/edit",
    name: "DashboardToolsAndResourcesEdit",
    component: () => import("@/views/Dashboard/ToolsAndResources/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/news",
    name: "DashboardNewsIndex",
    component: () => import("@/views/Dashboard/News/Index.vue"),
    meta: {
      middleware: auth,
      permission: "news",
    },
  },
  {
    path: "/admin/dashboard/news/create",
    name: "DashboardNewsCreate",
    component: () => import("@/views/Dashboard/News/Create.vue"),
    meta: {
      middleware: auth,
      permission: "news",
    },
  },
  {
    path: "/admin/dashboard/news/:id/edit",
    name: "DashboardNewsEdit",
    component: () => import("@/views/Dashboard/News/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "news",
    },
  },
  {
    path: "/admin/dashboard/news/:id",
    name: "DashboardNewsShow",
    component: () => import("@/views/Dashboard/News/Show.vue"),
    meta: {
      middleware: auth,
      permission: "news",
    },
  },
  {
    path: "/admin/dashboard/faq",
    name: "DashboardFaqIndex",
    component: () => import("@/views/Dashboard/Faq/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/faq/create",
    name: "DashboardFaqCreate",
    component: () => import("@/views/Dashboard/Faq/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/faq/:id/edit",
    name: "DashboardFaqEdit",
    component: () => import("@/views/Dashboard/Faq/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/menu",
    name: "DashboardMenuIndex",
    component: () => import("@/views/Dashboard/Menu/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/menu/create",
    name: "DashboardMenuCreate",
    component: () => import("@/views/Dashboard/Menu/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/menu/:id/edit",
    name: "DashboardMenuEdit",
    component: () => import("@/views/Dashboard/Menu/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/menu/:id",
    name: "DashboardMenuShow",
    component: () => import("@/views/Dashboard/Menu/Show.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/destination",
    name: "DashboardDestinationIndex",
    component: () => import("@/views/Dashboard/Destination/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/destination/create",
    name: "DashboardDestinationCreate",
    component: () => import("@/views/Dashboard/Destination/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/destination/:id/edit",
    name: "DashboardDestinationEdit",
    component: () => import("@/views/Dashboard/Destination/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/destination/:id",
    name: "DashboardDestinationShow",
    component: () => import("@/views/Dashboard/Destination/Show.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/story",
    name: "DashboardStoryIndex",
    component: () => import("@/views/Dashboard/Story/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/story/create",
    name: "DashboardStoryCreate",
    component: () => import("@/views/Dashboard/Story/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/story/:id/edit",
    name: "DashboardStoryEdit",
    component: () => import("@/views/Dashboard/Story/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/story/:id",
    name: "DashboardStoryShow",
    component: () => import("@/views/Dashboard/Story/Show.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  // admin event
  {
    path: "/admin/dashboard/event",
    name: "DashboardEventIndex",
    component: () => import("@/views/Dashboard/Event/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/create",
    name: "DashboardEventCreate",
    component: () => import("@/views/Dashboard/Event/Create.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/:id/edit",
    name: "DashboardEventEdit",
    component: () => import("@/views/Dashboard/Event/Edit.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/:id",
    name: "DashboardEventShow",
    component: () => import("@/views/Dashboard/Event/Show.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id",
    name: "DashboardEventDashboardIndex",
    component: () => import("@/views/Dashboard/Event/Dashboard/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/mail-config",
    name: "DashboardEventDashboardMailConfigIndex",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/MailConfig/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/registration-form",
    name: "DashboardEventDashboardRegistrationFormIndex",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/RegistrationForm/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/registration-form/create",
    name: "DashboardEventDashboardRegistrationFormCreate",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/RegistrationForm/Create.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/registration-form/:registrationFormId/edit",
    name: "DashboardEventDashboardRegistrationFormEdit",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/RegistrationForm/Edit.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/registration-form/:registrationFormId",
    name: "DashboardEventDashboardRegistrationFormShow",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/RegistrationForm/Show.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/participant",
    name: "DashboardEventDashboardParticipantIndex",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/Participant/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/participant/:registrationFormId/create",
    name: "DashboardEventDashboardParticipantCreate",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/Participant/Create.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/participant/:registrationFormId/:eventUserId",
    name: "DashboardEventDashboardParticipantEdit",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/Participant/Edit.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/participant/:registrationFormId/print",
    name: "DashboardEventDashboardParticipantPrint",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/Participant/Print.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/email-template",
    name: "DashboardEventDashboardEmailTemplateIndex",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/EmailTemplate/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/blast",
    name: "DashboardEventDashboardBlastIndex",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/Blast/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/print-id-card",
    name: "DashboardEventDashboardPrintIdCardIndex",
    component: () =>
      import("@/views/Dashboard/Event/Dashboard/PrintIdCard/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa",
    name: "DashboardEventDashboardPsaIndex",
    component: () => import("@/views/Dashboard/Event/Psa/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/venue",
    name: "DashboardEventDashboardPsaVenueIndex",
    component: () => import("@/views/Dashboard/Event/Psa/Venue/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/venue/create",
    name: "DashboardEventDashboardPsaVenueCreate",
    component: () => import("@/views/Dashboard/Event/Psa/Venue/Create.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/venue/:venue_id/edit",
    name: "DashboardEventDashboardPsaVenueEdit",
    component: () => import("@/views/Dashboard/Event/Psa/Venue/Edit.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/seller",
    name: "DashboardEventDashboardPsaSeller",
    component: () => import("@/views/Dashboard/Event/Psa/Seller.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/list-seller",
    name: "DashboardEventDashboardPsaListSeller",
    component: () => import("@/views/Dashboard/Event/Psa/ListSeller/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/list-seller/:seller_id",
    name: "DashboardEventDashboardPsaListSellerDetail",
    component: () => import("@/views/Dashboard/Event/Psa/ListSeller/Detail.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/buyer",
    name: "DashboardEventDashboardPsaBuyer",
    component: () => import("@/views/Dashboard/Event/Psa/Buyer.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/times",
    name: "DashboardEventDashboardPsaTimes",
    component: () => import("@/views/Dashboard/Event/Psa/Times.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/duration",
    name: "DashboardEventDashboardPsaDuration",
    component: () => import("@/views/Dashboard/Event/Psa/Duration.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/schedule",
    name: "DashboardEventDashboardPsaSchedule",
    component: () => import("@/views/Dashboard/Event/Psa/Schedule.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/setting-login",
    name: "DashboardEventDashboardPsaSettingLogin",
    component: () => import("@/views/Dashboard/Event/Psa/SettingLogin.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/dashboard/:id/psa/start-event",
    name: "DashboardEventDashboardPsaStartEvent",
    component: () => import("@/views/Dashboard/Event/Psa/StartEvent.vue"),
    meta: {
      middleware: auth,
      permissions: ["event", 'pco'],
    },
  },
  {
    path: "/admin/dashboard/event/psa/approval",
    name: "DashboardEventPsaApproval",
    component: () => import("@/views/Dashboard/Event/Psa/Approval.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  // RFS (REQUEST FOR SUPPORT)
  {
    path: "/admin/dashboard/rfs",
    name: "DashboardRfs",
    component: () => import("@/views/Rfs/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/tracking",
    name: "DashboardRfsTracking",
    component: () => import("@/views/Rfs/Tracking/Tracking.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/tracking/:id",
    name: "DashboardRfsTrackingDetail",
    component: () => import("@/views/Rfs/Tracking/TrackingDetail.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/mce/:id?",
    name: "DashboardRfsMce",
    component: () => import("@/views/Rfs/Mce/Index1.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/mce/2/:id?",
    name: "DashboardRfsMce2",
    component: () => import("@/views/Rfs/Mce/Index2.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/mce/3/:id?",
    name: "DashboardRfsMce3",
    component: () => import("@/views/Rfs/Mce/Index3.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/mce/4/:id?",
    name: "DashboardRfsMce4",
    component: () => import("@/views/Rfs/Mce/Index4.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/mce/5/:id?",
    name: "DashboardRfsMce5",
    component: () => import("@/views/Rfs/Mce/Index5.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/mce/preview/:id?",
    name: "DashboardRfsMcePreview",
    component: () => import("@/views/Rfs/Mce/Preview.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/incentive/:id?",
    name: "DashboardRfsIncentive",
    component: () => import("@/views/Rfs/Incentive/Index1.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/incentive/2/:id?",
    name: "DashboardRfsIncentive2",
    component: () => import("@/views/Rfs/Incentive/Index2.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/incentive/3/:id?",
    name: "DashboardRfsIncentive3",
    component: () => import("@/views/Rfs/Incentive/Index3.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/incentive/4/:id?",
    name: "DashboardRfsIncentive4",
    component: () => import("@/views/Rfs/Incentive/Index4.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/incentive/5/:id?",
    name: "DashboardRfsIncentive5",
    component: () => import("@/views/Rfs/Incentive/Index5.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/rfs/incentive/preview/:id?",
    name: "DashboardRfsIncentivePreview",
    component: () => import("@/views/Rfs/Incentive/Preview.vue"),
    meta: {
      middleware: auth,
      permissions: ["business", "pco"],
    },
  },
  {
    path: "/admin/dashboard/subvention",
    name: "DashboardSubventionIndex",
    component: () => import("@/views/Dashboard/Subvention/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/subvention/history",
    name: "DashboardSubventionHistory",
    component: () => import("@/views/Dashboard/Subvention/History.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/subvention/reject",
    name: "DashboardSubventionReject",
    component: () => import("@/views/Dashboard/Subvention/Reject.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/subvention/:status/:id",
    name: "DashboardSubventionShow",
    component: () => import("@/views/Dashboard/Subvention/Show.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/subvention/profil/:status/:id",
    name: "DashboardSubventionProfil",
    component: () =>
      import("@/views/Dashboard/Subvention/Detail/Profil/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/subvention/konsep/:status/:id",
    name: "DashboardSubventionKonsep",
    component: () =>
      import("@/views/Dashboard/Subvention/Detail/Konsep/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/subvention/strategis/:status/:id",
    name: "DashboardSubventionStrategis",
    component: () =>
      import("@/views/Dashboard/Subvention/Detail/Strategis/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/subvention/dokumen/:status/:id",
    name: "DashboardSubventionDokumen",
    component: () =>
      import("@/views/Dashboard/Subvention/Detail/Dokumen/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/subvention/dukungan/:status/:id",
    name: "DashboardSubventionDukungan",
    component: () =>
      import("@/views/Dashboard/Subvention/Detail/Dukungan/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  // pco-eo
  {
    path: "/admin/dashboard/pco-eo",
    name: "DashboardPcoEoIndex",
    component: () => import("@/views/Dashboard/PcoEo/Index.vue"),
    meta: {
      middleware: auth,
      permission: "pco",
    },
  },
  {
    path: "/admin/dashboard/pco-eo/create",
    name: "DashboardPcoEoCreate",
    component: () => import("@/views/Dashboard/PcoEo/Create.vue"),
    meta: {
      middleware: auth,
      permission: "pco",
    },
  },
  {
    path: "/admin/dashboard/pco-eo/:id/edit",
    name: "DashboardPcoEoEdit",
    component: () => import("@/views/Dashboard/PcoEo/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "pco",
    },
  },
  {
    path: "/admin/dashboard/pco-eo/:id",
    name: "DashboardPcoEoShow",
    component: () => import("@/views/Dashboard/PcoEo/Show.vue"),
    meta: {
      middleware: auth,
      permission: "pco",
    },
  },
  {
    path: "/admin/dashboard/accommodation",
    name: "DashboardAccommodationIndex",
    component: () => import("@/views/Dashboard/Accommodation/Index.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/accommodation/create",
    name: "DashboardAccommodationCreate",
    component: () => import("@/views/Dashboard/Accommodation/Create.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/accommodation/:id/edit",
    name: "DashboardAccommodationEdit",
    component: () => import("@/views/Dashboard/Accommodation/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/accommodation/:id",
    name: "DashboardAccommodationShow",
    component: () => import("@/views/Dashboard/Accommodation/Show.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },

  // phri hotel
  {
    path: "/admin/dashboard/phri-hotel",
    name: "DashboardPhriHotelIndex",
    component: () => import("@/views/Dashboard/PhriHotel/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/phri-hotel/create",
    name: "DashboardPhriHotelCreate",
    component: () => import("@/views/Dashboard/PhriHotel/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/phri-hotel/:id/edit",
    name: "DashboardPhriHotelEdit",
    component: () => import("@/views/Dashboard/PhriHotel/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/phri-hotel/:id/meeting-room",
    name: "DashboardPhriHotelMeetingRoomIndex",
    component: () => import("@/views/Dashboard/PhriHotel/MeetingRoom/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/phri-hotel/:id/meeting-room/create",
    name: "DashboardPhriHotelMeetingRoomCreate",
    component: () => import("@/views/Dashboard/PhriHotel/MeetingRoom/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/phri-hotel/:id/meeting-room/:room_id/edit",
    name: "DashboardPhriHotelMeetingRoomEdit",
    component: () => import("@/views/Dashboard/PhriHotel/MeetingRoom/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/phri-hotel/:id/room",
    name: "DashboardPhriHotelRoomIndex",
    component: () => import("@/views/Dashboard/PhriHotel/Room/Index.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/phri-hotel/:id/room/create",
    name: "DashboardPhriHotelRoomCreate",
    component: () => import("@/views/Dashboard/PhriHotel/Room/Create.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/phri-hotel/:id/room/:room_id/edit",
    name: "DashboardPhriHotelRoomEdit",
    component: () => import("@/views/Dashboard/PhriHotel/Room/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  // end phri hotel

  // PHRI INVENTORY
  {
    path: "/admin/dashboard/phri-inventory",
    name: "DashboardPhriInventoryIndex",
    component: () => import("@/views/Dashboard/PhriInventory/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },
  // Phri hotel bisnis
  {
    path: "/admin/dashboard/phri-inventory/hotel",
    name: "DashboardPhriInventoryHotelIndex",
    component: () => import("@/views/Dashboard/PhriInventory/Hotel/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/hotel/create",
    name: "DashboardPhriInventoryHotelCreate",
    component: () => import("@/views/Dashboard/PhriInventory/Hotel/Create.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/hotel/:id/edit",
    name: "DashboardPhriInventoryHotelEdit",
    component: () => import("@/views/Dashboard/PhriInventory/Hotel/Edit.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/hotel/:id/meeting-room",
    name: "DashboardPhriHotelInventoryMeetingRoomIndex",
    component: () => import("@/views/Dashboard/PhriInventory/Hotel/MeetingRoom/Index.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/hotel/:id/meeting-room/create",
    name: "DashboardPhriHotelInventoryMeetingRoomCreate",
    component: () => import("@/views/Dashboard/PhriInventory/Hotel/MeetingRoom/Create.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/hotel/:id/meeting-room/:room_id/edit",
    name: "DashboardPhriHotelInventoryMeetingRoomEdit",
    component: () => import("@/views/Dashboard/PhriInventory/Hotel/MeetingRoom/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/hotel/:id/room",
    name: "DashboardPhriHotelInventoryRoomIndex",
    component: () => import("@/views/Dashboard/PhriInventory/Hotel/Room/Index.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/hotel/:id/room/create",
    name: "DashboardPhriHotelInventoryRoomCreate",
    component: () => import("@/views/Dashboard/PhriInventory/Hotel/Room/Create.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/hotel/:id/room/:room_id/edit",
    name: "DashboardPhriHotelInventoryRoomEdit",
    component: () => import("@/views/Dashboard/PhriInventory/Hotel/Room/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  // end phri hotel bisnis

  // Phri venue bisnis
  {
    path: "/admin/dashboard/phri-inventory/venue",
    name: "DashboardPhriInventoryVenueIndex",
    component: () => import("@/views/Dashboard/PhriInventory/Venue/Index.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/venue/create",
    name: "DashboardPhriInventoryVenueCreate",
    component: () => import("@/views/Dashboard/PhriInventory/Venue/Create.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/venue/:id/edit",
    name: "DashboardPhriInventoryVenueEdit",
    component: () => import("@/views/Dashboard/PhriInventory/Venue/Edit.vue"),
    meta: {
      middleware: auth,
      permissions: ["business"],
    },
  },

  {
    path: "/admin/dashboard/phri-inventory/venue/:id/hall-room",
    name: "DashboardPhriInventoryVenueHallRoomIndex",
    component: () => import("@/views/Dashboard/PhriInventory/Venue/HallRoom/Index.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/venue/:id/hall-room/create",
    name: "DashboardPhriInventoryVenueHallRoomCreate",
    component: () => import("@/views/Dashboard/PhriInventory/Venue/HallRoom/Create.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-inventory/venue/:id/hall-room/:room_id/edit",
    name: "DashboardPhriInventoryVenueHallRoomEdit",
    component: () => import("@/views/Dashboard/PhriInventory/Venue/HallRoom/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  // end phri venue bisnis
  
  // phri venue
  {
    path: "/admin/dashboard/phri-venue",
    name: "DashboardPhriVenueIndex",
    component: () => import("@/views/Dashboard/PhriVenue/Index.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-venue/create",
    name: "DashboardPhriVenueCreate",
    component: () => import("@/views/Dashboard/PhriVenue/Create.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-venue/:id/edit",
    name: "DashboardPhriVenueEdit",
    component: () => import("@/views/Dashboard/PhriVenue/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-venue/:id/hall-room",
    name: "DashboardPhriVenueHallRoomIndex",
    component: () => import("@/views/Dashboard/PhriVenue/HallRoom/Index.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-venue/:id/hall-room/create",
    name: "DashboardPhriVenueHallRoomCreate",
    component: () => import("@/views/Dashboard/PhriVenue/HallRoom/Create.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/phri-venue/:id/hall-room/:room_id/edit",
    name: "DashboardPhriVenueHallRoomEdit",
    component: () => import("@/views/Dashboard/PhriVenue/HallRoom/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "accommodation",
    },
  },
  {
    path: "/admin/dashboard/venue",
    name: "DashboardVenueIndex",
    component: () => import("@/views/Dashboard/Venue/Index.vue"),
    meta: {
      middleware: auth,
      permission: "venue",
    },
  },
  {
    path: "/admin/dashboard/venue/create",
    name: "DashboardVenueCreate",
    component: () => import("@/views/Dashboard/Venue/Create.vue"),
    meta: {
      middleware: auth,
      permission: "venue",
    },
  },
  {
    path: "/admin/dashboard/venue/:id/edit",
    name: "DashboardVenueEdit",
    component: () => import("@/views/Dashboard/Venue/Edit.vue"),
    meta: {
      middleware: auth,
      permission: "venue",
    },
  },
  {
    path: "/admin/dashboard/venue/:id",
    name: "DashboardVenueShow",
    component: () => import("@/views/Dashboard/Venue/Show.vue"),
    meta: {
      middleware: auth,
      permission: "venue",
    },
  },
  {
    path: "/admin/dashboard/setting",
    name: "DashboardSettingIndex",
    component: () => import("@/views/Dashboard/Setting.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  {
    path: "/admin/dashboard/web-profile",
    name: "DashboardWebProfileIndex",
    component: () => import("@/views/Dashboard/WebProfile.vue"),
    meta: {
      middleware: auth,
      permission: "admin",
    },
  },
  // DASHBOARD UNAUTHENTICATED ROUTES
  {
    path: "/admin/dashboard/unauthenticated",
    name: "DashboardUnauthenticated",
    component: () => import("@/views/Dashboard/Unauthenticated.vue"),
  },
  // NOT FOUND PAGE
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    let user = JSON.parse(
      JSON.parse(JSON.stringify(localStorage.getItem("user")))
    );
    let isAccess = true;
    let isAccessRole = true;
    if (to.meta.roles) {
      if (!to.meta.roles.includes(user.role)) {
        isAccessRole = false;
      }
    } else {
      isAccessRole = false;
    }
    if (to.meta.permission) {
      if (!checkPermission(user?.access?.split("|"), to.meta.permission)) {
        isAccess = false;
      }
    }
    if (to.meta.permissions) {
      if (!checkPermissions(user?.access?.split("|"), to.meta.permissions)) {
        isAccess = false;
      }
    }

    if (!isAccess && !isAccessRole) {
      next({ name: "DashboardUnauthenticated" });
    }

    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };

    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
